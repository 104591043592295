import { Typography, makeStyles, Button, Box, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  inputProps: {
    color: 'black !important',
    height: 55,
  },
  inputLabelProps: {
    color: 'black !important',
  },
  helperTextProps: {
    color: 'black !important',
  },
  btn: {
    color: 'white !important',
    background: 'black !important',
    height: 55,
  },
  box: {
    width: '100%',
    height: '45vh !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightcyan',
    padding: '3px',
  },
  innerBox: {
    display: 'flex !important',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
}));
const EmailField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

const Email = () => {
  const classes = useStyles();

  const email = () => {
    return (
      <Box>
        <EmailField
          id="email"
          label="Email"
          type="email"
          className={classes.emailField}
          InputProps={{ className: classes.inputProps }}
          InputLabelProps={{ className: classes.inputLabelProps }}
          FormHelperTextProps={{ className: classes.helperTextProps }}
          helperText="Never Shared.  Only sweet deals."
        ></EmailField>{' '}
        <Button component="span" className={classes.btn}>
          Sign Up
        </Button>
      </Box>
    );
  };

  return (
    <Box className={classes.box}>
      <Box className={classes.innerBox}>
        <Typography variant="h3" display="initial">
          Like Saving Money?
        </Typography>
        <Typography varian="subtitle2">We only send sweet deals :)</Typography>
        {email()}
      </Box>
    </Box>
  );
};

export default Email;
