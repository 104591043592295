import {
  Typography,
  makeStyles,
  Button,
  Box,
  Grid,
  Link,
  ListItem,
  List,
  TextField,
  withStyles,
  createStyles,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { HashLink } from 'react-router-hash-link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {},
    mobileFooter: {
      backgroundColor: '#3C4F76',
      padding: '3vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    desktopFooter: {
      backgroundColor: '#3C4F76',
      padding: '3vh',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'flex-start',
    },
    icon: {
      paddingRight: '10px',
      display: 'inline',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
    },
    block: {
      display: 'block',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      color: 'white',
    },
    inputProps: {
      color: 'white !important',
      height: 55,
    },
    inputLabelProps: {
      color: 'white !important',
    },
    helperTextProps: {
      color: 'white !important',
    },
    btn: {
      color: 'black !important',
      background: 'white !important',
      height: 55,
    },
    item: {
      paddingLeft: '0 !important',
      cursor: 'pointer !important',
    },
    links: {
      textDecoration: 'none',
      color: 'white',
    },
  }),
);

const footerMenuButtons = [
  { label: 'About Us', href: '/#about' },
  { label: 'Create a Map', href: '/create' },
];

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();

  const footerCard = (title, content) => {
    return (
      <Box className={classes.card}>
        <Typography variant="h6">{title}</Typography>
        <Box>{content}</Box>
      </Box>
    );
  };
  const EmailField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  })(TextField);

  const getListItems = (label, href, key) => {
    return (
      <ListItem classes={{ root: classes.item }} key={key}>
        <HashLink
          // {...{
          //   key: label,
          //   color: 'inherit',
          //   to: href,
          // }}
          className={classes.links}
          smooth
          to={href}
        >
          {label}
        </HashLink>
      </ListItem>
    );
  };

  const listMenu = () => {
    return <List>{footerMenuButtons.map(({ label, href }, key) => getListItems(label, href, key))}</List>;
  };

  const footerMenu = () => footerCard('Menu', listMenu());

  const contact = () => footerCard('Contact', <Typography>contact@myjourneymap.net</Typography>);

  const followUs = () => {
    const list = [
      <a
        href="https://www.facebook.com/My-Journey-Map-105730178310913"
        key="first"
        className={classes.links}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon classes={{ root: classes.item }} />
      </a>,
      <a href="https://www.instagram.com/my_journey_map/" key="second" className={classes.links} target="_blank" rel="noreferrer">
        <InstagramIcon classes={{ root: classes.item }} />
      </a>,
      <a href="https://www.pinterest.com/myjourneymap/" key="third" className={classes.links} target="_blank" rel="noreferrer">
        <PinterestIcon classes={{ root: classes.item }} />
      </a>,
    ];
    return footerCard('Follow Us', list);
  };

  const signUpField = () => {
    const helper = () => (
      <Box component="form">
        <EmailField
          id="footer-email"
          label="Email"
          type="email"
          className={classes.emailField}
          InputProps={{ className: classes.inputProps }}
          InputLabelProps={{ className: classes.inputLabelProps }}
          FormHelperTextProps={{ className: classes.helperTextProps }}
          helperText="Never Shared.  Only sweet deals."
        ></EmailField>
        <Button component="span" className={classes.btn}>
          Sign Up
        </Button>
      </Box>
    );

    return footerCard('Never miss a deal', helper());
  };

  const displayDesktop = () => {
    return (
      <Grid className={classes.desktopFooter}>
        {footerMenu()}
        {signUpField()}
        {contact()}
        {followUs()}
      </Grid>
    );
  };
  const displayMobile = () => {
    return (
      <Grid className={classes.mobileFooter}>
        {footerMenu()}
        <br></br>
        {signUpField()}
        <br></br>
        {contact()}
        <br></br>
        {followUs()}
      </Grid>
    );
  };
  return <div className={classes.footer}>{useMediaQuery(theme.breakpoints.up('sm')) ? displayDesktop() : displayMobile()}</div>;
};

export default Footer;
