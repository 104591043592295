/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
import { useStyles } from '../../common/styles/global';
import { backendAPI } from '../../api/backendApi';

const orientationMap = [
  { orientation: 'landscape', primaryText: 'Landscape' },
  { orientation: 'portrait', primaryText: 'Portrait' },
];

const sizeMap = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

const renderOrientations = (handleOrientationClick, props, classes, selectedOrientation) =>
  orientationMap.map(({ orientation, primaryText }) => (
    <ListItem
      key={primaryText}
      button
      selected={orientation === selectedOrientation}
      onClick={() => handleOrientationClick(props, orientation)}
      className={classes.nested}
    >
      <ListItemText primary={primaryText} />
    </ListItem>
  ));

const renderSizes = (handleSizeClick, props, classes, selectedSize) =>
  sizeMap.map(({ text, value }) => (
    <ListItem
      key={text}
      button
      selected={value === selectedSize}
      onClick={() => handleSizeClick(props, value)}
      className={classes.nested}
    >
      <ListItemText primary={text} />
    </ListItem>
  ));

export const SelectLayout = ({ props }) => {
  const classes = useStyles();
  const [selectedSize, setSelectedSize] = useState('large');
  const [selectedOrientation, setSelectedOrientation] = useState('portrait');
  const [products, setProducts] = useState({});

  useEffect(() => {
    const getProducts = async () => {
      const products = await backendAPI.products.get();
      setProducts(products);
    };

    getProducts().catch((error) => console.log(error));
  }, []);

  const handleSizeClick = (props, value) => {
    props.modify({ size: value });
    setSelectedSize(value);
  };

  const handleOrientationClick = (props, value) => {
    props.modify({ orientation: value });
    setSelectedOrientation(value);
  };

  return (
    <>
      <List>
        <Typography variant="h5">Size</Typography>
        <Divider />
        {renderSizes(handleSizeClick, props, classes, selectedSize)}
        <br />
        <Typography variant="h5">Orientation</Typography>
        <Divider />
        {renderOrientations(handleOrientationClick, props, classes, selectedOrientation)}
      </List>
    </>
  );
};
