import StyledMap from './StyledMap';
import React from 'react';
import './Map.css';
import { MJMProps } from '../../mapState/MJMProps';

export function MapLiveView(props: MJMProps): React.ReactElement {
  const { width, height } = props.printDimensions;

  return (
    <div>
      <div className="mjm-rulerbox mjm-vert">
        <div className="mjm-rulerbox mjm-horiz">
          <div
            className="mjm-printarea"
            style={{
              aspectRatio: `${width / height}`,
              position: 'relative',
              width: '100%',
              backgroundColor: props.background,
              boxShadow: '10px 10px 2px grey',
              borderLeft: '1em solid #171219',
              borderTop: '1em solid #0D3030',
              borderRight: '1em solid #092020',
              borderBottom: '1em solid #041010',
            }}
            id="print"
          >
            <div
              className="mjm-maparea"
              style={{
                top: `${props.posterPercentages.map.y * 100}%`,
                left: `${props.posterPercentages.map.x * 100}%`,
                width: `${props.posterPercentages.map.w * 100}%`,
                height: `${props.posterPercentages.map.h * 100}%`,
              }}
            >
              <StyledMap {...props} />
            </div>
            {textBar(props.labels.title, props.labels.fontFamily, width, height, props.posterPercentages.title)}
            {textBar(props.labels.subtitle, props.labels.fontFamily, width, height, props.posterPercentages.subtitle)}
          </div>
          <div className="mjm-ruler mjm-vert">
            <span
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
              }}
            >
              {height} (inches)
            </span>
          </div>
        </div>
        <div className="mjm-rulerbox mjm-horiz">
          <div className="mjm-ruler mjm-horiz">
            <span>{width} (inches)</span>
          </div>
          <div /> {/*placeholder to get CSS alignment correct*/}
        </div>
      </div>
    </div>
  );
}

function textBar(
  text: string,
  fontFamily: string,
  width: number,
  height: number,
  location?: MJMProps['posterPercentages']['map'],
) {
  if (location === undefined) {
    return undefined;
  }

  const aspectRatio = (width / height) * (location.w / location.h);

  return (
    <div
      className="mjm-title"
      style={{
        top: `${location.y * 100}%`,
        left: `${location.x * 100}%`,
        width: `${location.w * 100}%`,
        height: `${location.h * 100}%`,
        fontFamily: fontFamily,
      }}
    >
      <svg className="mjm-fill" viewBox={`0 0 ${aspectRatio.toFixed(5)} 1`} xmlns="http://www.w3.org/2000/svg">
        <text x={`${(aspectRatio / 2).toFixed(5)}`} y="0.75" textAnchor="middle" fontSize="1" fill="black">
          {text}
        </text>
      </svg>
    </div>
  );
}
