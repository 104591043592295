/* eslint-disable react/prop-types */
import React from 'react';
import { CirclePicker } from 'react-color';
import { useStyles } from '../../common/styles/global';

const colors = [
  '#FFFFFF',
  '#000000',
  '#d9d9d9',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const SelectBorder = ({ props }) => {
  const classes = useStyles();
  return (
    <>
      <CirclePicker
        color={props.background}
        onChange={(c) => props.modify({ background: c.hex })}
        className={classes.colorPicker}
        circleSize={40}
        colors={colors}
      />
    </>
  );
};
