import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { backendAPI } from '../../api/backendApi';
import { Loading } from '../Loading/Loading';
import { MJMCastProps } from '../../mapState/useMJMState';
import Header from '../Header/Header.container';
import Footer from '../Footer/Footer.container';

const MapsListComponent = (props) => {
  const [mapData, setMapData] = useState(null);
  useEffect(() => {
    backendAPI.maps.list().then(setMapData);
  }, []);
  return (
    <>
      <Header />
      {mapData === null && <Loading />}
      {mapData !== null && mapData.map((map) => <MapLink key={map.journey_map_id} {...map} />)}
      <Footer />
    </>
  );
};

const MapLink = (props) => {
  const mapData = MJMCastProps(props.journey_map_data);
  return (
    <p>
      Map:{' '}
      <Link to={`/map/${props.journey_map_id}`}>
        {mapData.labels.title} - {mapData.labels.subtitle}
      </Link>
    </p>
  );
};

MapLink.propTypes = {
  journey_map_data: PropTypes.object,
  journey_map_id: PropTypes.string,
};

export default MapsListComponent;
