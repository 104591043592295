import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  shape: {
    borderRadius: '0',
  },
  //   palette: {
  //     primary: {
  //       main: "#64f58d",
  //     },
  //   },
});

export { theme };
