import React from 'react';
import Footer from '../Footer/Footer.container';
import Header from '../Header/Header.container';
import About from '../About/About.container';
import Home from '../Home/Home.container';
import Gallery from '../Gallery/Gallery.container';
import Email from '../Email/Email.container';

const IndexComponent = () => {
  return (
    <>
      <Header />
      <div id="/">
        <Home />
      </div>
      <div id="about">
        <About />
      </div>
      <Email />
      <div id="gallery">
        <Gallery />
      </div>
      <Footer />
    </>
  );
};

export default IndexComponent;
