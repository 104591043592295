import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { REACT_APP_MAPBOX_ACCESS_TOKEN } from '../config';

if (REACT_APP_MAPBOX_ACCESS_TOKEN === undefined) {
  throw new Error('Could not find REACT_APP_MAPBOX_ACCESS_TOKEN variable');
}
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

function withMap<T>(
  Component: React.FC<T & { map: mapboxgl.Map }>,
  extraParams: Partial<mapboxgl.MapboxOptions> = {},
  nonceFunction: (t: T) => unknown = () => 0,
): React.FC<T> {
  return (props: T) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [mapState, setMap] = useState<mapboxgl.Map | null>(null);
    const nonce = nonceFunction(props);
    const emptyFunction = () => {
      return;
    };
    // Intialize map when component mounts
    useEffect(() => {
      if (mapContainerRef.current === null) {
        return emptyFunction;
      }
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        attributionControl: false,
        ...extraParams,
      });

      setMap(map);
      // Clean up on unmount
      return () => {
        map.remove();
      };
    }, [nonce]);

    return (
      <>
        <div className="mjm-mapboxcanvas" ref={mapContainerRef} />
        {mapState && <Component map={mapState} {...props} />}
      </>
    );
  };
}

export default withMap;
