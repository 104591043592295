/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from '../../common/styles/global';

const styles = [
  {
    id: '1',
    value: 'mapbox://styles/jonathan2357/ckmf2d5f11rrx17o2e3mcg0r4',
    src: '/basic.png',
  },
  {
    id: '2',
    value: 'mapbox://styles/dloesch1/ck0pdthoe155b1cplahpp00hj',
    src: '/spidy.png',
  },
  {
    id: '3',
    value: 'mapbox://styles/dloesch1/cjr9hymkq030m2spk33gafilm',
    src: '/western.png',
  },
  {
    id: '4',
    value: 'mapbox://styles/jonathan2357/ckmf29y3k0pks17lbalsfxp7y',
    src: '/blueprint.png',
  },
  {
    id: '5',
    value: 'mapbox://styles/jonathan2357/ckmf28phb0u9917oem1rxf8r8',
    src: '/decimal.png',
  },
  {
    id: '6',
    value: 'mapbox://styles/dloesch1/cja1wd8nb07vz2spjwbc2dqlu',
    src: '/vintage.png',
  },
  {
    id: '7',
    value: 'mapbox://styles/dloesch1/ckl7970ej2q8w17pm7f0t96pv',
    src: '/outdoors.png',
  },
  {
    id: '8',
    value: 'mapbox://styles/dloesch1/cknnqu51z4mdl18l1leh4jxcr',
    src: '/lavender.png',
  },
  {
    id: '9',
    value: 'mapbox://styles/dloesch1/cknnys0vf13e717n0vi95gaxb',
    src: '/snow.png',
  },
  {
    id: '10',
    value: 'mapbox://styles/dloesch1/cknnwfq862dkg17nz5zl40ro1',
    src: '/santafe.png',
  },
  {
    id: '11',
    value: 'mapbox://styles/dloesch1/cknnz1zlg2hus17l8yo838l9g',
    src: '/golden.png',
  },
  {
    id: '12',
    value: 'mapbox://styles/dloesch1/cknnz58bm34zy17pb8ka4auq7',
    src: '/steel.png',
  },
];

export const SelectStyle = ({ props }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.styleContainer}>
        {styles.map((el) => {
          return (
            <Box
              onClick={() => props.modify({ style: el.value })}
              className={classes.mapStyle}
              style={{ backgroundImage: `url(${el.src})` }}
              key={el.id}
            ></Box>
          );
        })}
      </Box>
    </>
  );
};
