import { useReducer, useState, useEffect, useCallback } from 'react';
import { computeMJMState } from './computeMJMState';
import { MJMProps, MJMCurrentState, MJMModificationPayload } from './MJMProps';
import { reduceMJMState } from './reduceMJMState';
import { backendAPI } from '../api/backendApi';
import { StupidEvent } from './StupidEvent';
import { LngLatBoundsLike } from 'mapbox-gl';

const initialState: () => MJMCurrentState = () => ({
  version: '0.1',
  geo: {
    bearing: 0,
    lat: 33.7682,
    lng: -84.3627,
    pitch: 0,
    zoom: 13.5,
  },
  labels: {
    title: 'Atlanta',
    subtitle: 'Georgia',
    fontFamily: '"Abril Fatface", cursive',
  },
  layout: {
    orientation: 'portrait',
    size: 'large',
    template: 'title_bottom',
  },
  overlays: [],
  layoutNonce: 0,
  style: 'mapbox://styles/dloesch1/ck0pdthoe155b1cplahpp00hj',
  background: '#d9d9d9',
});

export function MJMCastProps(props: MJMProps): MJMProps {
  return props;
}
export function useMJMState(initialMapId?: string): MJMProps {
  const [state, modify] = useReducer(reduceMJMState, initialMapId, initialState);
  const [mapId, setMapId] = useState(initialMapId);
  const [loading, setLoading] = useState(!!initialMapId);
  useEffect(() => {
    (async () => {
      if (loading && mapId) {
        modify({
          type: 'reset',
          data: await backendAPI.maps.get(mapId),
        });
        setLoading(false);
      }
    })();
  }, [loading, mapId]);
  const computed = computeMJMState(state);
  const save = useCallback(async () => {
    if (!mapId) {
      const newMap = await backendAPI.maps.create(state);
      setMapId(newMap.journey_map_id);
      return newMap;
    } else {
      return await backendAPI.maps.update(mapId, state);
    }
  }, [state, mapId]);
  const [notifications] = useState(() => new StupidEvent<LngLatBoundsLike>());
  return {
    ...state,
    ...computed,
    loading,
    mapId,
    modify(data: MJMModificationPayload) {
      modify({ type: 'normal', data });
    },
    moveTo: (x) => notifications.notify(x),
    save,
    notifications,
  };
}
