import axios, { AxiosResponse } from 'axios';
import { REACT_APP_API_BASE_URL } from '../config';
import { MJMCurrentState } from '../mapState/MJMProps';

const backend = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function Handle(responsePromise: Promise<AxiosResponse<any>>): Promise<any> {
  const response = await responsePromise;
  if (response.status !== 200) {
    throw new Error('API Error');
  } else if (typeof response.data !== 'object' || response.data === null) {
    throw new Error('API Error');
  } else if (response.data.code !== 200 || response.data.status !== 200) {
    throw new Error('API Error');
  }
  return response.data.data;
}

export interface MapDataServerResponse {
  journey_map_data: MJMCurrentState;
  created_at: string;
  updated_at: string;
  user_id: string;
  journey_map_id: string;
}

export const backendAPI = {
  maps: {
    async create(data: MJMCurrentState): Promise<MapDataServerResponse> {
      const result = await Handle(
        backend.post('journey_map/', {
          journey_map_data: data,
        }),
      );
      if (typeof result.journey_map_id !== 'string') {
        throw new Error('API Error');
      }
      return result;
    },
    async update(id: string, data: MJMCurrentState): Promise<MapDataServerResponse> {
      const result = await Handle(
        backend.put('journey_map/', {
          journey_map_id: id,
          journey_map_data: data,
        }),
      );
      return result[0];
    },
    async get(id: string): Promise<MJMCurrentState> {
      const response = await Handle(backend.get(`journey_map/${id}`));
      if (!Array.isArray(response) || response.length !== 1) {
        throw new Error('API Error');
      }
      switch (response[0].journey_map_data?.version) {
        case '0.0':
          response[0].journey_map_data.labels.fontFamily = '"Abril Fatface", cursive';
          response[0].journey_map_data.version = '0.1';
          break;
        case '0.1':
          break;
        default:
          throw new Error('API Error');
      }
      return response[0].journey_map_data as MJMCurrentState;
    },
    async list(): Promise<MapDataServerResponse[]> {
      const response = await Handle(backend.get(`journey_map/list`));
      if (!Array.isArray(response)) {
        throw new Error('API Error');
      }
      return response;
    },
  },
  billing: {
    async checkout(journey_map_id: string): Promise<void> {
      return await Handle(
        backend.post('billing/create-checkout-session', {
          journey_map_id,
        }),
      );
    },
  },
  products: {
    async get(): Promise<MapDataServerResponse[]> {
      const response = await Handle(backend.get('printful/products/variants'));
      if (!Array.isArray(response) || !response.length) {
        throw new Error('Products API Error');
      }
      return response;
    },
  },
};
