import React from 'react';
import { Button } from '@material-ui/core';
import { useClerk } from '@clerk/clerk-react';

export const SignInButton = () => {
  const { openSignIn } = useClerk();
  return (
    <Button variant="contained" color="primary" disableElevation onClick={() => openSignIn({})}>
      Sign in
    </Button>
  );
};
