/* eslint-disable react/prop-types */
import React from 'react';
import { Collapse, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export const ListItemWrapper = ({ idx, clickHandler, text, child, selectedIndex }) => {
  return (
    <>
      <ListItem button onClick={(e) => clickHandler(e, idx)}>
        <ListItemText disableTypography primary={<Typography variant="h4">{text}</Typography>} />
        {selectedIndex === idx ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={selectedIndex === idx} timeout="auto" unmountOnExit>
        {child}
      </Collapse>
      <br />
      <Divider />
    </>
  );
};
