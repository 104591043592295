import React from 'react';
import { withRouter } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <>
      <p>Error</p>
    </>
  );
};

export default withRouter(ErrorPage);
