import React from 'react';
import { SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import { HashLink } from 'react-router-hash-link';
import { SignInButton } from './SignInButton';

import { AppBar, Toolbar, Typography, makeStyles, Box } from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuDrawer from './MenuDrawer';

const useStyles = makeStyles(() => ({
  mobileHamburger: {
    color: 'white',
    margin: '0 0 0 auto',
  },
  header: {
    backgroundColor: '#3C4F76 !important',
  },
  icon: {
    paddingRight: '10px',
    display: 'inline-block',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 500,
    size: '18px',
    marginLeft: '38px',
    display: 'inline-flex',
    textDecoration: 'none',
    color: 'white',
    verticalAlign: 'middle',
  },
  companyLogoCSS: {
    textDecoration: 'none',
    color: 'white',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {},
  right: {},
}));

const headersData = [
  {
    label: 'Home',
    href: '/#',
  },
  {
    label: 'About',
    href: '/#about',
  },
  {
    label: 'Create',
    href: '/create',
  },
  {
    label: 'My Saved Maps',
    href: '/maps',
  },
  {
    label: 'Gallery',
    href: '/#gallery',
  },
  {
    label: <ShoppingCartOutlinedIcon />,
    href: '/checkout',
  },
  {
    label: (
      <>
        <SignedIn>
          <UserButton />
        </SignedIn>
        <SignedOut>
          <SignInButton />
        </SignedOut>
      </>
    ),
  },
];

const Header = () => {
  const { header, menuButton, toolbar, left, right, companyLogoCSS, mobileHamburger } = useStyles();
  const theme = useTheme();

  const getMenuButtons = () => {
    return headersData.map(({ label, href }, index) => {
      if (href !== undefined) {
        return (
          <HashLink key={label} className={menuButton} smooth to={href}>
            {label}
          </HashLink>
        );
      } else {
        return (
          <span className={menuButton} key={index}>
            {label}
          </span>
        );
      }
    });
  };

  const companyLogo = () => {
    return (
      <Typography variant="h6" component="h1">
        <HashLink className={companyLogoCSS} smooth to="/#">
          My Journey Map
        </HashLink>
      </Typography>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box className={left}>{companyLogo()}</Box>
        <Box className={right}>{getMenuButtons()}</Box>
      </Toolbar>
    );
  };

  const displayMobile = () => <MenuDrawer />;

  return (
    <div className={header}>
      <AppBar>{useMediaQuery(theme.breakpoints.up('sm')) ? displayDesktop() : displayMobile()}</AppBar>
      <Toolbar /> {/* https://material-ui.com/components/app-bar/#fixed-placement */}
    </div>
  );
};

export default Header;
