import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import withMap from '../../mapState/withMap';
import { MJMProps } from '../../mapState/MJMProps';

export default withMap(StyledMap, {}, (x) => x.layout);
function StyledMap(props: MJMProps & { map: mapboxgl.Map }) {
  const { modify } = props;
  const desiredMapWidthInInches = props.printDimensions.width * props.posterPercentages.map.w;
  // function to calculate the extra amount of zoom needed to make the geographical
  // boundaries of the map on screen match up witht the final geographical boundaries of the map in print.
  const calculateExtraZoom = React.useCallback(() => {
    const previewSizeInPixels = props.map.getContainer().clientWidth;
    const printDPI = 300;
    return Math.log2(previewSizeInPixels / (desiredMapWidthInInches * printDPI));
  }, [props.map, desiredMapWidthInInches]);

  // adjust zoom whenever the calculation function changes, or whenever a resize happens
  React.useEffect(() => {
    function adjustZoom() {
      props.map.setZoom(props.geo.zoom + calculateExtraZoom());
    }
    adjustZoom();
    props.map.on('resize', adjustZoom);
    return () => {
      props.map.off('resize', adjustZoom);
    };
  }, [props.map, calculateExtraZoom, props.geo.zoom]);
  // pushing other various proprties from react -> mapbox:
  useEffect(() => {
    props.map.setStyle(props.style);
  }, [props.map, props.style]);
  useEffect(() => {
    props.map.jumpTo({
      center: { lat: props.geo.lat, lng: props.geo.lng },
      bearing: props.geo.bearing,
      pitch: props.geo.pitch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.map]);

  // MapboxGL does a bunch of user interactions for us.  But we want to have those variables represented as react state (and ultimately persisted),
  // so we hook into these events to read the state from mapbox into react:
  React.useEffect(() => {
    function readZoom() {
      // When reading zoom specifically, we adjust for the fact that the mapboxGL zoom level is the one on screen, whereas in
      // our app, we want to persist the "print zoom", which is the zoom level we will use when printing at print resolution
      // -- since that value doesn't change whenever the user resizes their screen.
      modify({ zoom: props.map.getZoom() - calculateExtraZoom() });
    }
    props.map.on('zoomend', readZoom);
    return () => {
      props.map.off('zoomend', readZoom);
    };
  }, [props.map, modify, calculateExtraZoom]);
  useEffect(() => {
    function readPosition() {
      modify({
        lat: props.map.getCenter().lat,
        lng: props.map.getCenter().lng,
        pitch: props.map.getPitch(),
        bearing: props.map.getBearing(),
      });
    }
    props.map.on('moveend', readPosition);
    props.map.on('zoomend', readPosition);
    return () => {
      props.map.off('moveend', readPosition);
      props.map.off('zoomend', readPosition);
    };
  }, [props.map, modify]);
  useEffect(() => {
    return props.notifications.listen((modification) => {
      const newMapLocation = props.map.cameraForBounds(modification);
      if (newMapLocation !== undefined) {
        props.map.flyTo(newMapLocation);
      }
    });
  }, [props.map, props.notifications]);

  return null;
}
