/* eslint-disable react/prop-types */
import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { Geocoder } from '../Geocoder/Geocoder';
import { REACT_APP_MAPBOX_ACCESS_TOKEN } from '../../config';

export const LocationSearch = ({ props }) => {
  return (
    <>
      <List component="div" disablePadding>
        <ListItem>
          <Geocoder
            accessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            onSelect={(place) => {
              props.moveTo(place.bbox);
              props.modify({
                title: place.text,
                subtitle: '',
              });
            }}
          ></Geocoder>
        </ListItem>
      </List>
    </>
  );
};
