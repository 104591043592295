/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import {
  List,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { fonts } from '../MapDesignerList/Fonts';
import { useStyles } from '../../common/styles/global';

const titles = [
  { label: 'Title', propKey: 'title' },
  { label: 'Subtitle', propKey: 'subtitle' },
];

const titleLocations = [
  { primary: 'None', template: 'no_title' },
  { primary: 'Top', template: 'title_top' },
  { primary: 'Bottom', template: 'title_bottom' },
];

const generateTitleFields = (props, classes) =>
  titles.map(({ label, propKey }) => (
    <Fragment key={label}>
      <TextField
        variant="outlined"
        label={label}
        value={props.labels[propKey]}
        onChange={(e) => props.modify({ [propKey]: e.target.value })}
        className={classes.textField}
      />
      <br />
    </Fragment>
  ));

const generateTitleLocations = (clickHandler, props, classes, titleLocation) =>
  titleLocations.map(({ primary, template }) => (
    <ListItem
      button
      key={primary}
      selected={template === titleLocation}
      onClick={() => clickHandler(props, template)}
      className={classes.nested}
    >
      <ListItemText primary={primary} />
    </ListItem>
  ));

const populateFonts = () =>
  fonts.map((font) => (
    <MenuItem key={font.key} value={font.fontFamily} style={{ fontFamily: font.fontFamily }}>
      {font.fontName}
    </MenuItem>
  ));

const handleFont = (e, props) => {
  props.modify({ fontFamily: e.target.value });
};

export const TitleListItem = ({ props }) => {
  const classes = useStyles();
  const [titleLocation, setTitleLocation] = useState('title_bottom');

  const handleTitleLocationClick = (props, template) => {
    props.modify({ template });
    setTitleLocation(template);
  };
  const fontFamilyName = props.labels.fontFamily.replace(/"/g, "'");
  const { titles, selectLabel, selectEmpty, titleLocation: titleLocationClass } = classes;

  return (
    <>
      <List component="div" disablePadding>
        <div className={titles}>
          {generateTitleFields(props, classes)}
          <FormControl variant="outlined">
            <InputLabel className={selectLabel} htmlFor="font-selector">
              Font
            </InputLabel>
            <Select label="Font" onChange={(e) => handleFont(e, props)} className={selectEmpty} value={fontFamilyName}>
              {populateFonts()}
            </Select>
          </FormControl>
        </div>
        <Divider />
        <div className={titleLocationClass}>
          <Typography variant="h5">Title Location</Typography>
          {generateTitleLocations(handleTitleLocationClick, props, classes, titleLocation)}
        </div>
      </List>
    </>
  );
};
