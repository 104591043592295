import { Typography, makeStyles, Button, Box, Container } from '@material-ui/core';
import React from 'react';
import backgroundImage from '../../assets/imgs/chairPoster.svg';
import backgroundImage2 from '../../assets/imgs/Outdoors-1613434454409.png';
import MapIcon from '@material-ui/icons/Map';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'rgba(211, 255, 244, .7)',
  },
  mobileDisplay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'rgba(211, 255, 244, .7)',
  },
  overlay: {
    backgroundImage: `url(${backgroundImage2})`,
    backgroundSize: 'fill',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    margin: '0',
    padding: '0',

    // backgroundColor: "#9AB7FF",
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // background: "rgba(242, 229, 215, 0.4)",
    padding: '8vh 8vw',
  },
  button: {
    padding: '15px 25px',
    marginTop: '3vh',
  },
}));
const aboutInfo = {
  title: 'About Us',
  content:
    'Hodor. Hodor hodor, hodor. Hodor hodor hodor hodor hodor. Hodor. Hodor! Hodor hodor, hodor; hodor hodor hodor. Hodor. Hodor hodor; hodor hodor - hodor, hodor, hodor hodor. Hodor, hodor. Hodor. Hodor, hodor hodor hodor; hodor hodor; hodor hodor hodor! Hodor hodor HODOR! Hodor hodor... Hodor hodor hodor..',
};
const About = () => {
  const { top, left, right, box, button, overlay, mobileDisplay } = useStyles();
  const theme = useTheme();

  const leftSide = () => {
    return <Container className={left}></Container>;
  };
  const rightSide = () => {
    return (
      <Container className={right}>
        <Box className={box}>
          <Typography variant="h2">{aboutInfo.title}</Typography>
          <Typography variant="body1">{aboutInfo.content}</Typography>
          <Button href="/create" variant="contained" color="primary" startIcon={<MapIcon />} className={button}>
            Create Map
          </Button>
        </Box>
      </Container>
    );
  };
  const desktop = () => {
    return (
      <Box className={top}>
        {leftSide()}
        {rightSide()}
      </Box>
    );
  };

  const mobile = () => {
    return (
      <Box className={mobileDisplay}>
        {/* {leftSide()} */}
        {rightSide()}
      </Box>
    );
  };
  return <Box className={overlay}>{useMediaQuery(theme.breakpoints.up('sm')) ? desktop() : mobile()}</Box>;
};

export default About;
