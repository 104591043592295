export const fonts = [
  {
    fontName: 'Abril',
    key: 'abril',
    fontFamily: "'Abril Fatface', cursive",
  },
  {
    fontName: 'Bangers',
    key: 'bangers',
    fontFamily: "'Bangers', cursive",
  },
  {
    fontName: 'Bebas',
    key: 'bebas',
    fontFamily: "'Bebas Neue', cursive",
  },
  {
    fontName: 'Bungee',
    key: 'bungee',
    fontFamily: "'Bungee', cursive",
  },
  {
    fontName: 'Dancing',
    key: 'dancing',
    fontFamily: "'Dancing Script', cursive",
  },
  {
    fontName: 'Fascinate',
    key: 'fascinate',
    fontFamily: "'Fascinate', cursive",
  },
  {
    fontName: 'Frijole',
    key: 'frijole',
    fontFamily: "'Frijole', cursive",
  },
  {
    fontName: 'Girassol',
    key: 'girassol',
    fontFamily: "'Girassol', cursive",
  },
  {
    fontName: 'Goblin',
    key: 'goblin',
    fontFamily: "'Goblin One', cursive",
  },
  {
    fontName: 'Graduate',
    key: 'graduate',
    fontFamily: "'Graduate', cursive",
  },
  {
    fontName: 'Great Vibes',
    key: 'greatVibes',
    fontFamily: "'Great Vibes', cursive",
  },
  {
    fontName: 'Shadows',
    key: 'shadows',
    fontFamily: "'Shadows Into Light', cursive",
  },
  {
    fontName: 'Krona',
    key: 'Krona',
    fontFamily: "'Krona One', sans-serif",
  },
  {
    fontName: 'Lobster',
    key: 'Lobster',
    fontFamily: "'Lobster', cursive",
  },
  {
    fontName: 'Newsreader',
    key: 'newsreader',
    fontFamily: "'Newsreader', serif",
  },
  {
    fontName: 'Permanent Marker',
    key: 'permanentMarker',
    fontFamily: "'Permanent Marker', cursive",
  },
  {
    fontName: 'Righteous',
    key: 'righteous',
    fontFamily: "'Righteous', cursive",
  },
  { fontName: 'Spartan', key: 'spartan', fontFamily: "'Spartan', sans-serif" },
];
