/* eslint-disable react/prop-types */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-find-dom-node */
import xhr from 'xhr';
import React, { useEffect, useState, createRef, useCallback } from 'react';
import { TextField, Autocomplete } from '@material-ui/core';

export const Geocoder = ({
  endpoint = 'https://api.tiles.mapbox.com',
  inputClass = '',
  resultClass = '',
  inputPlaceholder = 'Search',
  source = 'mapbox.places',
  proximity = '',
  bbox = '',
  types = 'country,region,postcode,district,place,locality,neighborhood',
  onSuggest = () => {},
  onSelect = () => {},
  accessToken = '',
}) => {
  const [results, setResults] = useState([]);
  const [focus, setFocus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTime, setSearchTime] = useState(new Date());
  const [location, setLocation] = useState(null);

  const clickOption = useCallback(
    (place, listLocation) => {
      onSelect(place);
      setFocus(listLocation);

      return false;
    },
    [onSelect, setFocus],
  );

  useEffect(() => {
    if (results.length) {
      const currentLocation = results.find(({ place_name: placeName }) => placeName === location);
      const currentLocationIndex = results.findIndex(({ place_name: placeName }) => placeName === location);
      if (!!currentLocation && currentLocationIndex > -1) {
        clickOption(currentLocation, currentLocationIndex);
        setLocation(null);
      }
    }
  }, [results, location, clickOption]);

  const inputRef = createRef();

  const onResult = (err, res, body, currentSearchTime) => {
    const { features = '' } = body;
    // searchTime is compared with the last search to set the state
    // to ensure that a slow xhr response does not scramble the
    // sequence of autocomplete display.
    if (!err && body && features && searchTime <= currentSearchTime) {
      setSearchTime(searchTime);
      setLoading(false);
      setResults(features);
      setFocus(null);
      onSuggest(results);
    }
  };

  const onInput = (e) => {
    setLoading(true);
    const { target: { value = '' } = {} } = e;

    if (value === '') {
      setResults([]);
      setFocus(null);
      setLoading(false);
    } else {
      search(endpoint, source, accessToken, proximity, bbox, types, value, onResult);
    }
  };

  const moveFocus = (dir) => {
    if (loading) {
      return;
    }
    setFocus(focus === null ? 0 : Math.max(0, Math.min(results.length - 1, focus + dir)));
  };

  const acceptFocus = () => {
    if (focus !== null) {
      onSelect(results[focus]);
    }
  };

  const onKeyDown = (e) => {
    switch (e.which) {
      // up
      case 38:
        e.preventDefault();
        moveFocus(-1);
        break;
      // down
      case 40:
        moveFocus(1);
        break;
      // accept
      case 13:
        if (results.length && focus == null) {
          clickOption(results[0], 0);
        }
        acceptFocus();
        break;
    }
  };

  return (
    <div>
      <Autocomplete
        sx={{ width: 300 }}
        id="location-search"
        className={resultClass}
        onChange={(e, value) => setLocation(value)}
        options={results.map(({ place_name }) => place_name)}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              inputRef={inputRef}
              className={inputClass}
              onInput={onInput}
              onKeyDown={onKeyDown}
              placeholder={inputPlaceholder}
              type="text"
            />
          );
        }}
      />
    </div>
  );
};

const search = (endpoint, source, accessToken, proximity, bbox, types, query, callback) => {
  const searchTime = new Date();
  const encodedQuery = encodeURIComponent(query);
  const proximityParam = proximity ? '&proximity=' + proximity : '';
  const bboxParam = bbox ? '&bbox=' + bbox : '';
  const typesParam = types ? '&types=' + encodeURIComponent(types) : '';
  const params = `access_token=${accessToken}${proximityParam}${bboxParam}${typesParam}`;
  const uri = `${endpoint}/geocoding/v5/${source}/${encodedQuery}.json?${params}`;

  xhr(
    {
      uri,
      json: true,
    },
    (err, res, body) => {
      callback(err, res, body, searchTime);
    },
  );
};
