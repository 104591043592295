import { makeStyles, Box, Container } from '@material-ui/core';
import React from 'react';
import ImageList from '@material-ui/core/ImageList';
import backgroundImage from '../../assets/imgs/Monochrome-1613437789625.png';
import './Gallery.css';
import GalleryCard from '../GalleryCard/GalleryCard.component';

const useStyles = makeStyles(() => ({
  overlay: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    margin: '0',
    padding: '0',
  },
  container: {
    boxSizing: 'border-box',
    height: '100%',
    margin: '0 auto 24px',
  },
  gridList: {
    width: '100%',
    marginTop: '0px !important',
    paddingTop: '2vh !important',
  },
}));

const Gallery = () => {
  const { container, overlay, gridList } = useStyles();

  let list = [
    {
      caption: 'Test post',
      id: '17910412450870366',
      media_type: 'IMAGE',
      media_url:
        'https://scontent.cdninstagram.com/v/t51.29350-15/200648025_855921678360670_6950809174358035190_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=8ae9d6&_nc_ohc=sbG9HfQLsFkAX9b1hNi&_nc_ht=scontent.cdninstagram.com&oh=5ba172e48a03296b6f57bdf734a6853e&oe=60CBFACB',
      permalink: 'https://www.instagram.com/p/CQEuYz1NPKZ/',
      timestamp: '2021-06-13T20:30:05+0000',
      username: 'my_journey_map',
    },
  ];

  return (
    <Box className={overlay}>
      <div id="instafeed"></div>
      <Container className={container} id="test">
        <ImageList variant="masonry" cols={3} gap={10} className={gridList}>
          {list.map((item, i) => (
            <GalleryCard key={i} img={item.media_url} caption={item.caption} link={item.permalink} />
          ))}
        </ImageList>
      </Container>
    </Box>
  );
};

export default Gallery;
