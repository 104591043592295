import { MJMCurrentState, MJMComputedState } from './MJMProps';
import { Immutable } from 'immer';

export function computeMJMState(state: Immutable<MJMCurrentState>): MJMComputedState {
  const printDimensions = (() => {
    const dimensions: Record<MJMCurrentState['layout']['size'], [number, number]> = {
      large: [30, 48],
      medium: [18, 24],
      small: [12, 18],
    };
    const [dim1, dim2] = dimensions[state.layout.size];
    switch (state.layout.orientation) {
      case 'portrait':
        return {
          height: Math.max(dim1, dim2),
          width: Math.min(dim1, dim2),
        };
      case 'landscape':
        return {
          height: Math.min(dim1, dim2),
          width: Math.max(dim1, dim2),
        };
    }
  })();

  const posterPercentages: MJMComputedState['posterPercentages'] = (() => {
    const mapMarginInches = 2;
    const titleHeight = printDimensions.width / 12 / printDimensions.height;
    switch (state.layout.template) {
      case 'no_title':
        return {
          map: {
            x: mapMarginInches / printDimensions.width,
            w: 1 - (2 * mapMarginInches) / printDimensions.width,
            y: mapMarginInches / printDimensions.height,
            h: 1 - (2 * mapMarginInches) / printDimensions.height,
          },
        };
      case 'title_bottom':
        return {
          map: {
            x: mapMarginInches / printDimensions.width,
            w: 1 - (2 * mapMarginInches) / printDimensions.width,
            y: mapMarginInches / printDimensions.height,
            h: 1 - (3 * mapMarginInches) / printDimensions.height - titleHeight,
          },
          title: {
            x: mapMarginInches / printDimensions.width,
            w: 1 - (2 * mapMarginInches) / printDimensions.width,
            y: 1 - mapMarginInches / printDimensions.height - titleHeight,
            h: titleHeight,
          },
        };
      case 'title_top':
        return {
          map: {
            x: mapMarginInches / printDimensions.width,
            w: 1 - (2 * mapMarginInches) / printDimensions.width,
            y: (2 * mapMarginInches) / printDimensions.height + titleHeight,
            h: 1 - (3 * mapMarginInches) / printDimensions.height - titleHeight,
          },
          title: {
            x: mapMarginInches / printDimensions.width,
            w: 1 - (2 * mapMarginInches) / printDimensions.width,
            y: mapMarginInches / printDimensions.height,
            h: titleHeight,
          },
        };
    }
  })();

  if (state.labels.subtitle !== '' && posterPercentages.title !== undefined) {
    posterPercentages.subtitle = {
      x: posterPercentages.title.x,
      w: posterPercentages.title.w,
      y: posterPercentages.title.y + (3 * posterPercentages.title.h) / 4,
      h: posterPercentages.title.h / 4,
    };
    posterPercentages.title.h /= 2;
  }

  return {
    posterPercentages,
    printDimensions,
  };
}
