/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import { Button, makeStyles, createStyles } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { backendAPI } from '../../api/backendApi';
import { MJMCastProps } from '../../mapState/useMJMState';

import { ListItemWrapper } from '../MapDesignerListItems/ListItemWrapper';
import { LocationSearch } from '../MapDesignerListItems/LocationSearch';
import { SelectStyle } from '../MapDesignerListItems/SelectStyle';
import { SelectLayout } from '../MapDesignerListItems/SelectLayout';
import { TitleListItem } from '../MapDesignerListItems/TitleListItem';
import { SelectBorder } from '../MapDesignerListItems/SelectBorder';

const stripePromise = loadStripe(
  'pk_test_51IsB4uDLym1f0uzd1Re5KynSDFHIa8DI8nsLda9KQZ7GRwHcWSC7ydY8rSdr2l9iZWAhmKnSdpoyORzDuWYmRDHn00bgL5k0xf',
);

const useStyles = makeStyles(() => {
  return createStyles({
    stack: {
      marginTop: '1rem',
    },
  });
});

export const MapDesignerList = (rawProps) => {
  const history = useHistory();
  const props = MJMCastProps(rawProps);
  const { stack } = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (e, idx) => {
    selectedIndex === idx ? setSelectedIndex() : setSelectedIndex(idx);
  };

  const listItemMap = [
    { text: 'Location', child: <LocationSearch props={props} /> },
    { text: 'Style', child: <SelectStyle props={props} /> },
    { text: 'Layout', child: <SelectLayout props={props} /> },
    { text: 'Title', child: <TitleListItem props={props} /> },
    { text: 'Border', child: <SelectBorder props={props} /> },
  ];

  const renderListItems = () =>
    listItemMap.map(({ text, child }, idx) => (
      <ListItemWrapper
        key={idx}
        idx={idx}
        clickHandler={handleListItemClick}
        text={text}
        child={child}
        selectedIndex={selectedIndex}
      />
    ));

  const { save, mapId } = props;

  const saveMap = async () => {
    const result = await save();
    if (mapId !== result.journey_map_id) {
      history.push(`/map/${result.journey_map_id}`);
    }
  };

  const checkout = async () => {
    const stripe = await stripePromise;
    const { id: sessionId } = await backendAPI.billing.checkout(mapId);
    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      throw new Error(`Stripe Checkout Error: ${error}`);
    }
  };

  return (
    <>
      {renderListItems()}
      <Stack className={stack} spacing={2} direction="row">
        <Button variant="contained" onClick={saveMap}>
          {!mapId && 'Save map'}
          {mapId && 'Save changes'}
        </Button>
        {mapId && (
          <Button variant="contained" onClick={checkout}>
            Checkout
          </Button>
        )}
      </Stack>
    </>
  );
};
