import { MJMCastProps } from '../../mapState/useMJMState';
import FrozenMap from '../MapLiveView/FrozenMap';
import React from 'react';
import { computeMJMState } from '../../mapState/computeMJMState';
import { Box, Container } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
export function SampleMap({ map }) {
  const map2 = MJMCastProps({ ...map, ...computeMJMState(map) });
  return (
    <div className="mjm-fill">
      <Container>
        <div style={{ width: 500, height: 350 }}>
          <FrozenMap {...map2} />
        </div>
      </Container>
    </div>
  );
}
