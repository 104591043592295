import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import IndexComponent from './components/Index/Index.container';
import CreateComponent from './components/Create/Create.container';
import ErrorComponent from './components/Error/ErrorPage.container';
// import AboutComponent from './components/About/About.container';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../src/theme';
import { ClerkProvider } from '@clerk/clerk-react';
import { REACT_APP_CLERK_API_URL } from './config';
import MapsListComponent from './components/MapsListComponent/MapsListComponent';
import ReceiptComponent from './components/ReceiptComponent/ReceiptComponent';

const RouteMap = [
  { path: '/', component: IndexComponent },
  { path: '/error', component: ErrorComponent },
  { path: '/create', component: CreateComponent },
  { path: '/map/:mapId', component: CreateComponent },
  { path: '/maps', component: MapsListComponent },
  { path: '/receipt/:order_id', component: ReceiptComponent },
  // { path: '/#about', component: IndexComponent },
];

const App = () => {
  const renderRoutes = () => RouteMap.map(({ path, component }) => <Route key={path} exact path={path} component={component} />);

  return (
    <>
      <div className="App">
        <>
          <ThemeProvider theme={theme}>
            <Router>
              <ClerkProviderWithNavigate>
                <Switch>
                  {renderRoutes()}
                  <Redirect to="error" />
                </Switch>
              </ClerkProviderWithNavigate>
            </Router>
          </ThemeProvider>
        </>
      </div>
    </>
  );
};
// Wrap ClerkProvider so we can use the useHistory() hook
// eslint-disable-next-line react/prop-types
function ClerkProviderWithNavigate({ children }) {
  const { push } = useHistory();
  return (
    <ClerkProvider
      frontendApi={REACT_APP_CLERK_API_URL}
      navigate={(to) => {
        return push(to);
      }}
    >
      {children}
    </ClerkProvider>
  );
}

export default App;
