// eslint-disable-next-line no-redeclare
import { makeStyles } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  mapStyle: {
    height: '150px',
    width: '150px',
    margin: '5px',
    borderRadius: '50%',
    backgroundPosition: 'center',
  },
  styleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginBottom: '10px !important',
    marginTop: '10px !important',
  },
  colorPicker: {
    margin: '10px',
    marginBottom: '1em',
  },
  conatiner: {
    width: '90%',
    margin: 'auto',
  },
  wrapper: {
    width: '80%',
  },
  background: {
    backgroundColor: '#FCEEEE',
    paddingTop: '3em',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    minWidth: 194,
  },
  selectLabel: {
    marginTop: theme.spacing(1),
  },
  titles: {
    marginBottom: theme.spacing(3),
  },
  titleLocation: {
    marginTop: theme.spacing(3),
  },
}));

export { theme, useStyles };
