import { MJMCurrentState } from '../mapState/MJMProps';

export const spiderMap: MJMCurrentState = {
  version: '0.1',
  background: '#d9d9d9',
  geo: { bearing: 0, lat: 40.65279572599377, lng: -73.95501410066348, pitch: 0, zoom: 14.595878029578042 },
  labels: {
    subtitle: '"With great power comes great responsibility"',
    title: 'The Amazing Spider Map!',
    fontFamily: '"Abril Fatface", cursive',
  },
  layout: { orientation: 'portrait', size: 'large', template: 'title_bottom' },
  overlays: [],
  style: 'mapbox://styles/dloesch1/ck0pdthoe155b1cplahpp00hj',
};
