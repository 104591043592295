import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { backendAPI } from '../../api/backendApi';
import { Loading } from '../Loading/Loading';
import { MJMCastProps } from '../../mapState/useMJMState';
import Header from '../Header/Header.container';
import Footer from '../Footer/Footer.container';

const ReceiptComponent = (props) => {
  const { params } = useRouteMatch();
  const parsedParams = new URLSearchParams(useLocation().search);
  const orderCancelled = <div>Order has been cancelled</div>;
  const orderSuccess = <div>Order has been successfully placed</div>;
  return (
    <>
      <Header />
      {parsedParams.get('cancelled') === 'true' && orderCancelled}
      {parsedParams.get('success') === 'true' && orderSuccess}
      Receipt
      <Footer />
    </>
  );
};

export default ReceiptComponent;
