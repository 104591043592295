/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { MJMCastProps } from '../../mapState/useMJMState';
import { MapLiveView } from '../MapLiveView/MapLiveView';
import { MapDesignerList } from '../MapDesignerList/MapDesignerList';
import { useStyles } from '../../common/styles/global';

export const MapDesigner = (rawProps) => {
  const props = MJMCastProps(rawProps);
  const { background } = useStyles();
  const { printDimensions: { width, height } = {} } = props;

  return (
    <>
      <Grid container className={background}>
        <Grid item xs={8}>
          <Container
            style={{
              maxWidth: `calc((100vh - 30px)*${(width / height).toFixed(2)})`,
            }}
          >
            <MapLiveView {...props} />
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container>
            <MapDesignerList {...props} />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
