import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { NoEncryptionTwoTone } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  imageBox: {},
  imageText: {},
  media: { height: 'auto' },
  root: {
    marginBottom: '10px',
    maxWidth: 350,
  },
  text: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0),
  },
  link: {
    textDecoration: 'none',
  },
}));

const GalleryCard = (props) => {
  const { media, root, text, link } = useStyles();

  return (
    <a href={props.link} target="_blank" rel="noreferrer" className={link}>
      <Card className={root}>
        <CardActionArea>
          <CardMedia className={media} component="img" height="140" image={props.img} title={props.caption} />{' '}
          <CardContent className={text}>
            <div>{props.caption}</div>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
};

GalleryCard.propTypes = {
  img: PropTypes.string,
  caption: PropTypes.string,
  link: PropTypes.string,
};

export default GalleryCard;
