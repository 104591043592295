import React from 'react';
import { MapDesigner } from '../MapDesigner/MapDesigner';
import { useParams } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { useMJMState } from '../../mapState/useMJMState';
import Header from '../Header/Header.container';
import Footer from '../Footer/Footer.container';

const CreateComponent = () => {
  const { mapId } = useParams();
  const mapState = useMJMState(mapId);
  if (mapState.loading) {
    return <Loading />;
  }
  return (
    <>
      <Header />
      <MapDesigner {...mapState} />
      <Footer />
    </>
  );
};

export default CreateComponent;
