import { MJMCurrentState, MJMModificationPayload } from './MJMProps';

import { Immutable, Draft, produce } from 'immer';

export function reduceMJMState(
  state: MJMCurrentState,
  payload: { type: 'normal'; data: MJMModificationPayload } | { type: 'reset'; data: MJMCurrentState },
): MJMCurrentState {
  switch (payload.type) {
    case 'normal':
      return immerReducer(state, payload.data);
    case 'reset':
      return payload.data;
  }
}
const immerReducer = produce((state: Draft<MJMCurrentState>, payload: Immutable<MJMModificationPayload>) => {
  if (payload.lat !== undefined) {
    state.geo.lat = payload.lat;
  }
  if (payload.lng !== undefined) {
    state.geo.lng = payload.lng;
  }
  if (payload.zoom !== undefined) {
    state.geo.zoom = payload.zoom;
  }
  if (payload.pitch !== undefined) {
    state.geo.pitch = payload.pitch;
  }
  if (payload.bearing !== undefined) {
    state.geo.bearing = payload.bearing;
  }
  if (payload.style !== undefined) {
    state.style = payload.style;
  }
  if (payload.title !== undefined) {
    state.labels.title = payload.title;
  }
  if (payload.subtitle !== undefined) {
    state.labels.subtitle = payload.subtitle;
  }
  if (payload.fontFamily !== undefined) {
    state.labels.fontFamily = payload.fontFamily;
  }
  if (payload.size !== undefined) {
    state.layout.size = payload.size;
  }
  if (payload.orientation !== undefined) {
    state.layout.orientation = payload.orientation;
  }
  if (payload.template !== undefined) {
    state.layout.template = payload.template;
  }
  if (payload.background !== undefined) {
    state.background = payload.background;
  }
});
