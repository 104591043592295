import { useEffect } from 'react';
import { MJMProps } from '../../mapState/MJMProps';
import withMap from '../../mapState/withMap';

function FrozenMap(props: MJMProps & { map: mapboxgl.Map }) {
  useEffect(() => {
    props.map.setStyle(props.style);
  }, [props.map, props.style]);
  useEffect(() => {
    props.map.jumpTo({
      center: { lat: props.geo.lat, lng: props.geo.lng },
      zoom: props.geo.zoom,
      bearing: props.geo.bearing,
      pitch: props.geo.pitch,
    });
  }, [props.map, props.geo.lat, props.geo.lng, props.geo.zoom, props.geo.bearing, props.geo.pitch]);
  useEffect(() => {
    props.map.repaint = true;
  }, [props.map]);

  return null;
}

export default withMap(FrozenMap, { interactive: false });
