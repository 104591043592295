import { Typography, makeStyles, Button, Box } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import MapIcon from '@material-ui/icons/Map';
import React from 'react';
import StyledMap from '../MapLiveView/StyledMap';
import backgroundImg from '../../assets/imgs/Streets-1613431491272.png';
import { useMJMState } from '../../mapState/useMJMState';
import { spiderMap } from '../../sampleMaps/spiderMap';
import { SampleMap } from '../SampleMap/SampleMap';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import backgroundImage2 from '../../assets/imgs/chairPoster.svg';

const useStyles = makeStyles(() => ({
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'rgba(154, 183, 255, .7)',
  },
  mobileDisplay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: '50vh',
    width: '100%',
    background: 'rgba(154, 183, 255,)',
  },
  mobileDisplay2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: '50vh',
    width: '100%',
    background: 'rgba(196, 196, 220, .7)',
  },
  overlay: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    margin: '0',
    padding: '0',
  },
  mobileOverlay: {
    backgroundImage: `url(${backgroundImage2})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100vw',
    margin: '0',
    padding: '0',
  },
  createButton: { margin: '1vh' },
  search: {
    marginTop: '2vh',
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  leftText: {
    display: 'inline-block',
    textAlign: 'left',
    height: '100%',
  },
  right: {
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  frame: {
    height: '70vh',
    width: '50vh',
    boxShadow: '20px 20px 10px black',
    border: '1em solid white',
  },
  mobileText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightBox: {},
  button: {},
}));

const Home = () => {
  const mapState = useMJMState();
  const theme = useTheme();
  const {
    top,
    left,
    right,
    frame,
    leftText,
    search,
    overlay,
    mobileDisplay,
    createButton,
    mobileOverlay,
    mobileText,
    mobileDisplay2,
  } = useStyles();

  const createMapButton = () => {
    return (
      <Button href="/create" variant="contained" color="primary" startIcon={<MapIcon />} className={createButton}>
        Create Map
      </Button>
    );
  };
  const searchBarBox = () => {
    return (
      <>
        <SearchBar
          className={search}
          onChange={(newValue) => console.log({ value: newValue })}
          onRequestSearch={() => console.log(this.state.value)}
        />
        {createMapButton()}
      </>
    );
  };

  const leftSide = () => {
    return (
      <Box className={left}>
        {' '}
        <Box className={leftText}>
          {desktopComponentText()}
          {searchBarBox()}{' '}
        </Box>
      </Box>
    );
  };

  const rightSide = () => {
    return <Box className={right}>{<SampleMap map={spiderMap}></SampleMap>}</Box>;
  };

  const desktop = () => {
    return (
      <Box className={overlay}>
        <Box className={top}>
          {leftSide()}
          {rightSide()}
        </Box>
      </Box>
    );
  };
  const desktopComponentText = () => {
    return (
      <>
        <Typography variant="h1">Hey You!</Typography>
        <Typography variant="h4">Where ya been?</Typography>
      </>
    );
  };
  const mobileComponentText = () => {
    return (
      <div className={mobileText}>
        <Typography variant="h3">Hey You!</Typography>
        <Typography variant="h5">Where ya been?</Typography>
      </div>
    );
  };

  const mobile = () => {
    return (
      <Box className={mobileOverlay}>
        <div className={mobileDisplay}></div>
        <Box id="mobileDisplay" className={mobileDisplay2}>
          {/* {rightSide()} */}
          {mobileComponentText()}
          {createMapButton()}
        </Box>
      </Box>
    );
  };
  return <>{useMediaQuery(theme.breakpoints.up('sm')) ? desktop() : mobile()}</>;
};

export default Home;
