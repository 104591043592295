/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class StupidEvent<T> {
  private readonly listeners: Record<number, (event: T) => void> = {};
  private autoInc = 0;
  notify(event: T) {
    Object.values(this.listeners).forEach((l) => l(event));
  }
  listen(listener: (event: T) => void): () => void {
    const key = this.autoInc++;
    this.listeners[key] = listener;
    return () => delete this.listeners[key];
  }
}
